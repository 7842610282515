import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'

import parseShopifyMetafield from 'helpers/parseShopifyMetafield'

import s from './styles.module.css'

const ProductHeaderContent = ({
  isSimpleProduct,
  title,
  activeVariant,
  description,
  tags,
  size,
  className,
}) => {
  // const [stockistPostcode, setStockistPostcode] = useState(null)
  const titleParts = title.split(' | ')
  const variantTitle = activeVariant?.title

  let rrp = null
  if (activeVariant.metafields) {
    const matchingMeta = parseShopifyMetafield(activeVariant.metafields, 'rrp')
    if (matchingMeta && matchingMeta.length) {
      rrp = matchingMeta[0].value
    }
  }

  return (
    <div className={classNames(s.container, className)}>
      <h1 className={s.title}>
        {titleParts[0]}
        {titleParts.length > 1 && (
          <span>{titleParts.slice(1).join(' | ')}</span>
        )}
      </h1>
      {!isSimpleProduct &&
        variantTitle !== 'Default Title' &&
        variantTitle !== 'No Embellishment' && (
          <p className={s.subTitle}>
            {size
              ? size.map(({ values }) => values.map((v) => `${v} `))
              : variantTitle}
          </p>
        )}
      {rrp && <p className={s.price}>RRP £{rrp}</p>}
      <div
        dangerouslySetInnerHTML={{ __html: description }}
        className={s.description}
      />
      {tags?.length > 0 && (
        <div className={s.tags}>
          {tags.map((tag, i) => {
            const property = tag.substring(0, tag.indexOf(':'))
            const value = tag.substring(tag.indexOf(':') + 1)
            return (
              <span key={`${tag}${i}`} className={s.tag}>
                {property} -&nbsp;<span>{value}</span>
              </span>
            )
          })}
        </div>
      )}
      <Link
        to="/stockists/locator"
        className={s.cta}
        data-cy="open-locator-overlay"
        state={{
          modal: true,
          // stockistPostcode
        }}
      >
        Find a stockist
      </Link>
      {/* <input
        type="text"
        defaultValue={stockistPostcode}
        name="stockistPostcode"
        onChange={(e) => setStockistPostcode(e.target.value)}
      /> */}
      {activeVariant?.sku && (
        <span className={s.sku}>Product Code {activeVariant.sku}</span>
      )}
    </div>
  )
}

ProductHeaderContent.propTypes = {
  isSimpleProduct: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
  activeVariant: PropTypes.object,
  size: PropTypes.array,
  className: PropTypes.string,
}
ProductHeaderContent.defaultProps = {
  isSimpleProduct: false,
  title: '',
  description: '',
  tags: [],
  activeVariant: {},
  size: [],
  className: '',
}

export default ProductHeaderContent
