import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ProductItem from 'components/ProductItem'
import Slider from 'components/Slider'

import s from './styles.module.css'

// todo: we should limit this in graphql
const ProductRelated = ({ parentProduct, products, className }) => {
  if (!products || products?.length < 1) return null

  const sliderRef = useRef(null)
  const sliderActive = products.products.length > 4
  const settings = {
    responsive: [
      {
        breakpoint: 10000,
        ...(sliderActive
          ? {
              settings: {
                infinite: true,
                arrows: true,
                dots: false,
                fade: false,
                slidesToShow: 4,
                // adaptiveHeight: true,
              },
            }
          : { settings: 'unslick' }),
      },
      {
        breakpoint: 899,
        settings: 'unslick',
      },
    ],
  }

  return (
    <div className={classNames(s.container, className)}>
      <h3 className={s.title}>You might also like</h3>
      <Slider
        parentRef={sliderRef}
        className={classNames(s.slider, {
          [s.sliderActive]: sliderActive,
        })}
        {...settings}
      >
        {products.products
          .filter((f) => f.id !== parentProduct)
          .slice(0, 8)
          .map((p) => (
            <Fragment key={p.id}>
              <ProductItem data={p} className={s.slide} />
            </Fragment>
          ))}
      </Slider>
    </div>
  )
}

ProductRelated.propTypes = {
  parentProduct: PropTypes.string,
  products: PropTypes.object,
  className: PropTypes.string,
}
ProductRelated.defaultProps = {
  parentProduct: '',
  products: {},
  className: '',
}

export default ProductRelated
