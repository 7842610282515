import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ProductHeaderGallery from 'components/ProductHeaderGallery'
import ProductHeaderContent from 'components/ProductHeaderContent'
import ProductVariants from 'components/ProductVariants'
import ProductRelated from 'components/ProductRelated'

import s from './styles.module.css'

const Product = ({ data, pageContext }) => {
  const product = data.shopifyProduct
  const {
    id,
    title,
    description,
    descriptionHtml,
    tags,
    options,
    variants,
    images,
    metafields,
    relatedProducts,
  } = product

  const [activeVariantIndex, setActiveVariantIndex] = useState(0)
  const activeVariant = variants[activeVariantIndex]
  const isSimpleProduct = variants.length < 1
  const getTags = tags.filter((f) => f.includes(':'))

  const handleVariantClick = (variantIndex) => {
    setActiveVariantIndex(variantIndex)
    window.scrollTo(0, 0)
  }

  return (
    <Layout {...pageContext}>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={`${title} | Chalk UK`} description={description} />
      {/* {isSimpleProduct ? 'SIMPLE PRODUCT' : 'VARIANT PRODUCT'} */}
      <div className={s.container}>
        <div className={s.header}>
          <ProductHeaderGallery
            isSimpleProduct={isSimpleProduct}
            images={images}
            variants={variants}
          />
          <ProductHeaderContent
            isSimpleProduct={isSimpleProduct}
            title={title}
            metafields={metafields}
            activeVariant={activeVariant}
            description={descriptionHtml}
            tags={getTags}
            size={options.filter(({ name }) => name === 'Size')}
          />
          {getTags?.length > 0 && (
            <div className={s.tags}>
              {getTags.map((tag, i) => {
                const property = tag.substring(0, tag.indexOf(':'))
                const value = tag.substring(tag.indexOf(':') + 1)
                return (
                  <span key={`${tag}${i}`} className={s.tag}>
                    {property} -&nbsp;<span>{value}</span>
                  </span>
                )
              })}
            </div>
          )}
        </div>
        {options.filter(({ name }) => name === 'Size').length === 0 && (
          <ProductVariants
            variants={variants}
            handleVariantClick={handleVariantClick}
          />
        )}
        <ProductRelated parentProduct={id} products={relatedProducts} />
      </div>
    </Layout>
  )
}

Product.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Product

export const pageQuery = graphql`
  query ShopifyProductByHandle($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      description
      descriptionHtml
      tags
      images {
        localFile {
          id
          name
          childImageSharp {
            gatsbyImageData(
              width: 900
              placeholder: NONE
              sizes: "(max-width: 400px) 400px, (max-width: 500px) 500px, (max-width: 900px) 900px, (max-width: 6000px) 700px"
              breakpoints: [400, 500, 700, 900]
            )
          }
        }
        thumb: localFile {
          id
          childImageSharp {
            gatsbyImageData(
              width: 59
              height: 79
              layout: FIXED
              placeholder: NONE
            )
          }
        }
      }
      metafields {
        value
        type
        key
      }
      options {
        name
        values
      }
      variants {
        id
        title
        price
        sku
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 700, placeholder: NONE)
            }
          }
        }
        metafields {
          value
          type
          key
        }
      }
      relatedProducts: relatedPr {
        products {
          id
          title
          handle
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 320
                  placeholder: NONE
                  aspectRatio: 0.75
                )
              }
            }
          }
          metafields {
            value
            type
            key
          }
          variants {
            metafields {
              value
              type
              key
            }
          }
        }
      }
    }
  }
`
