import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SlickSlider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import s from './styles.module.css'

const ProductHeaderGallery = ({
  // isSimpleProduct,
  images,
  // variants,
  className,
}) => {
  const sliderRef = useRef()

  const settings = {
    fade: false,
    arrows: false,
    adaptiveHeight: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 899,
        settings: {
          dots: true,
        },
      },
    ],
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => <ul>{dots}</ul>,
  }

  const renderSlide = (img, alt, index) => {
    if (!img) return null
    return (
      <div key={`${img.id}${index}`} className={s.slide}>
        <GatsbyImage
          image={getImage(img)}
          alt={alt || ''}
          backgroundColor="#eee"
        />
      </div>
    )
  }

  const handleGoToSlide = (index) => {
    return sliderRef?.current?.slickGoTo(index)
  }

  return (
    <div className={classNames(s.container, className)}>
      <div className={s.thumbs} data-cy="product-thumbs">
        {images?.length &&
          images.slice(0, 5).map((img, i) => {
            const thumbImg = img?.thumb
            if (!thumbImg) return null
            return (
              <button
                key={thumbImg.id}
                type="button"
                className={s.thumb}
                onClick={() => handleGoToSlide(i)}
              >
                <GatsbyImage
                  image={getImage(thumbImg)}
                  backgroundColor="#eee"
                  alt=""
                />
              </button>
            )
          })}
      </div>
      <SlickSlider ref={sliderRef} className={s.slider} {...settings}>
        {images.length > 0 &&
          images.map((img, i) => {
            if (!img) return null
            return renderSlide(img.localFile || null, img.name, i)
          })}
        {/* {!isSimpleProduct &&
          variants.length > 0 &&
          variants.map((img, i) =>
            renderSlide(
              img?.image?.localFile.childImageSharp.fluid || null,
              img.name,
              i
            )
          )} */}
      </SlickSlider>
    </div>
  )
}

ProductHeaderGallery.propTypes = {
  isSimpleProduct: PropTypes.bool,
  images: PropTypes.array,
  variants: PropTypes.array,
  className: PropTypes.string,
}
ProductHeaderGallery.defaultProps = {
  isSimpleProduct: false,
  images: [],
  variants: [],
  className: '',
}

export default ProductHeaderGallery
