import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import parseShopifyMetafield from 'helpers/parseShopifyMetafield'
import Slider from 'components/Slider'

import s from './styles.module.css'

const ProductVariants = ({ variants, handleVariantClick, className }) => {
  if (!variants || variants?.length < 2) return null

  const sliderRef = useRef(null)
  const sliderActive = variants.length > 4
  const settings = {
    responsive: [
      {
        breakpoint: 10000,
        ...(sliderActive
          ? {
              settings: {
                infinite: true,
                arrows: true,
                dots: false,
                fade: false,
                slidesToShow: 4,
                // adaptiveHeight: true,
              },
            }
          : { settings: 'unslick' }),
      },
      {
        breakpoint: 899,
        settings: 'unslick',
      },
    ],
  }

  const renderRrp = (m) => {
    const rrp = parseShopifyMetafield(m, 'rrp')
    if (!rrp || rrp.length === 0) return null
    return <p className={s.price}>£{rrp[0].value}</p>
  }

  return (
    <Slider
      parentRef={sliderRef}
      className={classNames(s.container, className, {
        [s.sliderActive]: sliderActive,
      })}
      {...settings}
    >
      {variants.map((variant, variantIndex) => {
        const image = variant?.image?.localFile || null

        return (
          <Fragment key={variant.id}>
            <button
              type="button"
              className={s.variant}
              onClick={() => handleVariantClick(variantIndex, image)}
            >
              {image ? (
                <div className={s.imgWrap}>
                  <GatsbyImage
                    className={s.img}
                    image={getImage(image)}
                    backgroundColor="#eee"
                    // alt={variant.image?.altText || ''}
                    alt=""
                    style={{ position: 'absolute' }}
                  />
                </div>
              ) : (
                <span className={s.noImage} />
              )}
              <h2 className={s.title}>{variant.title}</h2>
              {variant.metafields && renderRrp(variant.metafields)}
            </button>
          </Fragment>
        )
      })}
    </Slider>
  )
}

ProductVariants.propTypes = {
  variants: PropTypes.array,
  handleVariantClick: PropTypes.func,
  className: PropTypes.string,
}
ProductVariants.defaultProps = {
  variants: [],
  handleVariantClick: () => {},
  className: '',
}

export default ProductVariants
